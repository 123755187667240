import contactBanner from '../assets/img/contact/01_banner.jpg';

export const contactPageData = {
  pageName: '聯絡我們',
  pageNameEn: 'Contact',
  mainBg: contactBanner,
  titleWord: [
    {
      word: 'Contact',
      ifBold: true,
    },
    {
      word: 'Us',
      ifBold: false,
    },
  ],
  bottomBgColor: '#fff',
};

export const temp = [];
