<template>
  <div id="top_banner">
    <div
      class="colorbg"
      :style="{backgroundColor: bottomBgColor}">
    </div>

    <div class="inner w1400">

      <div class="page_title">
        <small class="page_bread">
          首頁 -&nbsp;
          <span class="txt-gray">
            {{pageName}}
          </span>
        </small>

        <h2 class="txt-right txt-theme txt-up">
          <span
            :class="{'txt-bold' : titleWord[0].ifBold}">
              {{titleWord[0].word}}
          </span>
          <span
            v-if="titleWord[1]"
            :class="{'txt-bold' : titleWord[1].ifBold}">
              &nbsp;{{titleWord[1].word}}
          </span>
        </h2>
      </div>

      <div class="block">
        <div class="pic">
          <figure
            :style="{
              backgroundImage: `url(${mainBg})`
            }">
          </figure>
          <p
            class="shachou txt-white"
            v-if="$route.name==='TopMessage'">
              Kirin Engineering株式會社<br>
              代表取締役社長<br><br>
              吉川 孝宏
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBanner',
  props: {
    pageName: { type: String },
    mainBg: { type: String },
    titleWord: {
      type: Array,
      default: () => [
        {
          word: '',
          ifBold: false,
        },
        {
          word: '',
          ifBold: true,
        },
      ],
    },
    bottomBgColor: {
      type: String,
      default: '#f8f8f8',
    },
  },
  components: {
  },
};
</script>
