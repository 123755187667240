<template>
  <section id="contact">
    <TopBanner
      v-if="contactPageData"
      :mainBg="contactPageData.mainBg"
      :pageName="contactPageData.pageName"
      :titleWord="contactPageData.titleWord"
      :bottomBgColor="contactPageData.bottomBgColor"/>

    <div class="w1400 main_padding">
      <div class="tabs">
        <router-link
          :to="{
            name: 'Contact',
            params: { type: 'company' },
          }"
          class="tab active">
            <p>企業合作</p>
        </router-link>
        <router-link
          :to="{
            name: 'Contact',
            params: { type: 'recruit' },
          }"
          class="tab">
            <p>員工招募</p>
        </router-link>
      </div>

      <div class="form_outer">
        <div class="left">
          <!-- 公司的文案 -->
          <p v-if="type==='company'">
            如您有任何意見或疑問，敬請不吝指教。我們將盡力為您解答。 為了加快回覆速度，請依照您的需求，並填寫相關必填欄位，感謝您的配合！
          </p>
          <!-- 招募的文案 -->
          <p v-else-if="type==='recruit'">
            日商麒麟技術非常重視求職者的職務申請，履歷都經由 HR 及團隊審核，因履歷眾多，審核需花一定的時間，我們將不一一回覆每封履歷，請求職者悉知並耐心等候。
          </p>
          <small>
            標註 <span class="txt-theme">※</span> 欄位為必填
          </small>
        </div>

        <!-- 公司合作 -->
        <div class="right" v-if="type==='company'">
          <div class="input_item">
            <h6>
              公司名稱
              <span class="small txt-theme">※</span>
            </h6>
            <input
              type="text"
              class="h6"
              v-model="companyForm.companyName">
          </div>
          <div class="input_item">
            <h6>
              聯絡人
              <span class="small txt-theme">※</span>
            </h6>
            <input
              type="text"
              class="h6"
              v-model="companyForm.contactMan">
          </div>
          <div class="input_item">
            <h6>
              聯絡電話
              <span class="small txt-theme">※</span>
            </h6>
            <input
              type="text"
              class="h6"
              v-model="companyForm.tel">
          </div>
          <div class="input_item">
            <h6>
              電子信箱
              <span class="small txt-theme">※</span>
            </h6>
            <input
              type="text"
              class="h6"
              v-model="companyForm.email">
          </div>
          <div class="input_item">
            <h6>
              提案附件
            </h6>
            <div class="input_item_text h6">
              {{companyForm.appendixName}}
            </div>
            <label for="company_form_file" class="upload_btn">
              <img src="@/assets/img/contact/02_Appendix_icon.svg" alt="">
            </label>
            <input
              type="file"
              id="company_form_file"
              ref="company_form_file"
              class="h6"
              accept=".pdf"
              @change="()=>{changeCompanyAppendix()}">
          </div>
          <small>※ 限 PDF 格式，10MB 以內</small>
          <div class="input_item">
            <textarea
              class="h6"
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="備註"
              v-model="companyForm.remark">
            </textarea>
          </div>
          <div class="submit_box">
            <input
              type="checkbox"
              name=""
              id="company_agree"
              v-model="companyForm.agree">
            <label for="company_agree">
              <span></span>
              <small>已同意隱私權及個資保護聲明</small>
            </label>
            <button
              class="btn"
              :disabled="companySubmitBtnDisable"
              @click="submitCompany()">
                <div class="circle">
                  <div class="in">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.52" height="24.52" viewBox="0 0 24.52 24.52">
                      <g transform="translate(-419 -2068)">
                      <circle
                        cx="12.26" cy="12.26" r="12.26"
                        transform="translate(419 2068)"
                        fill="none"/>
                      <path
                        d="M2715.789,1791.683l3.489,3.489-3.489,3.489"
                        transform="translate(-2285.897 284.71)"
                        fill="none"
                        stroke="" stroke-width="1.5"/>
                      </g>
                    </svg>
                  </div>
                </div>
                <p>Submit</p>
            </button>
          </div>
        </div>
        <!-- 人員招募 -->
        <div class="right" v-if="type==='recruit'">
          <div class="input_item">
            <h6>
              姓名
              <span class="small txt-theme">※</span>
            </h6>
            <input
              type="text"
              class="h6"
              v-model="recruitForm.name">
          </div>
          <div class="input_item">
            <h6>
              聯絡電話
              <span class="small txt-theme">※</span>
            </h6>
            <input
              type="text"
              class="h6"
              v-model="recruitForm.tel">
          </div>
          <div class="input_item">
            <h6>
              電子信箱
              <span class="small txt-theme">※</span>
            </h6>
            <input
              type="text"
              class="h6"
              v-model="recruitForm.email">
          </div>
          <div class="input_item">
            <h6>
              附件
            </h6>
            <div class="input_item_text h6">
              {{recruitForm.appendixName}}
            </div>
            <label for="recruit_form_file" class="upload_btn">
              <img src="@/assets/img/contact/02_Appendix_icon.svg" alt="">
            </label>
            <input
              type="file"
              id="recruit_form_file"
              ref="recruit_form_file"
              class="h6"
              accept=".pdf"
              @change="()=>{changeRecruitAppendix()}">
          </div>
          <small>※ 限 PDF 格式，10MB 以內</small>
          <div class="input_item">
            <textarea
              class="h6"
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="備註"
              v-model="recruitForm.remark">
            </textarea>
          </div>
          <div class="submit_box">
            <input
              type="checkbox"
              name=""
              id="recruit_agree"
              v-model="recruitForm.agree">
            <label for="recruit_agree">
              <span></span>
              <small>已同意隱私權及個資保護聲明</small>

            </label>
            <button
              class="btn"
              :disabled="recruitSubmitBtnDisable"
              @click="submitRecruit()">
                <div class="circle">
                  <div class="in">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.52" height="24.52" viewBox="0 0 24.52 24.52">
                      <g transform="translate(-419 -2068)">
                      <circle
                        cx="12.26" cy="12.26" r="12.26"
                        transform="translate(419 2068)"
                        fill="none"/>
                      <path
                        d="M2715.789,1791.683l3.489,3.489-3.489,3.489"
                        transform="translate(-2285.897 284.71)"
                        fill="none"
                        stroke="" stroke-width="1.5"/>
                      </g>
                    </svg>
                  </div>
                </div>
                <p>Submit</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="type === 'recruit'"
      class="vacancies">
        <div class="inner w1400">
          <h5 class="txt-white sub_title">
            日商麒麟技術職缺查詢
          </h5>
          <a
            href="https://www.104.com.tw/company/1a2x6bigrj"
            target="_blank"
            class="btn-border txt-white">
              <div class="circle">
                <div class="in">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.52" height="24.52" viewBox="0 0 24.52 24.52">
                    <g transform="translate(-419 -2068)">
                    <circle
                      cx="12.26" cy="12.26" r="12.26"
                      transform="translate(419 2068)"
                      fill="none"/>
                    <path
                      d="M2715.789,1791.683l3.489,3.489-3.489,3.489"
                      transform="translate(-2285.897 284.71)"
                      fill="none"
                      stroke="" stroke-width="1.5"/>
                    </g>
                  </svg>
                </div>
              </div>
              <p>人力銀行</p>
          </a>
        </div>
        <img src="@/assets/img/contact/02_1.svg" alt="">
    </div>
  </section>
</template>

<script>
import { contactPageData } from '@/utils/contactPageData';
import requestApi from '@/lib/http/index';
import TopBanner from '@/components/public/TopBanner.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'Contact',
  mixins: [titleMixin],
  props: {
    type: {
      type: String,
      default: 'company',
    },
  },
  components: {
    TopBanner,
  },
  data() {
    return {
      contactPageData,
      companySubmitBtnDisable: false,
      companyForm: {
        companyName: '',
        contactMan: '',
        tel: '',
        email: '',
        appendixName: '',
        remark: '',
        agree: false,
      },
      recruitSubmitBtnDisable: false,
      recruitForm: {
        name: '',
        tel: '',
        email: '',
        appendixName: '',
        remark: '',
        agree: false,
      },
    };
  },
  methods: {
    async sendAppendix(data) {
      const result = await requestApi(
        'addAssets.index',
        { files: [data] },
      );

      return result;
    },
    resetCompanyForm() {
      const companyFromKeys = Object.keys(this.companyForm);
      companyFromKeys.forEach((key) => {
        if (key === 'agree') {
          this.companyForm[key] = false;
        }

        this.companyForm[key] = '';
      });
    },
    resetRecuritForm() {
      const recruitFormKeys = Object.keys(this.recruitForm);
      recruitFormKeys.forEach((key) => {
        if (key === 'agree') {
          this.recruitForm[key] = false;
        }

        this.recruitForm[key] = '';
      });
    },
    changeCompanyAppendix() {
      console.log(this.$refs.company_form_file.files[0]);
      if (this.$refs.company_form_file.files[0]) {
        if (
          this.$refs.company_form_file.files[0].type === 'application/pdf'
          && this.$refs.company_form_file.files[0].size < 10 * 1024 * 1024
        ) {
          this.companyForm.appendixName = this.$refs.company_form_file.files[0].name;
        } else {
          this.$customSWAL({ icon: 'error', title: '限 PDF 格式，10MB 以內' });
          this.$refs.company_form_file.value = '';
          this.companyForm.appendixName = '';
          console.log('clear', this.$refs.company_form_file.files[0]);
        }
      } else {
        this.companyForm.appendixName = '';
      }
    },
    async sendCompanyForm(appendixPath = null) {
      console.log('sendCompanyForm', appendixPath);
      const result = await requestApi(
        'contact.company',
        {
          data: {
            company: this.companyForm.companyName,
            contact: this.companyForm.contactMan,
            mobile: this.companyForm.tel,
            email: this.companyForm.email,
            memo: this.companyForm.remark,
            agree: (this.companyForm.agree) ? 'Y' : 'N',
            status: 'N',
            link: (appendixPath) ? `storage/uploads/${appendixPath}` : '',
          },
        },
      );

      if (result.error) {
        this.$customSWAL({ icon: 'error', title: result.error });
      } else {
        this.companyForm.appendixName = '';
        this.$refs.company_form_file.value = '';
        this.$customSWAL({ icon: 'success', title: '已收到您的資訊' });
      }

      this.resetCompanyForm();
    },
    checkAttach() {
      if (!this.$refs.company_form_file?.files[0]) {
        return true;
      }

      const isPdf = this.$refs.company_form_file?.files[0].type === 'application/pdf';
      const isSizeLegal = this.$refs.company_form_file?.files[0].size < 10 * 1024 * 1024;

      if (isPdf && isSizeLegal) {
        return true;
      }

      return false;
    },
    checkCompanyFormValue() {
      if (!this.companyForm.companyName) {
        return '請填寫公司名稱';
      }
      if (!this.companyForm.contactMan) {
        return '請填寫聯絡人';
      }
      if (!this.companyForm.tel) {
        return '請填寫聯絡電話';
      }
      if (Number.isNaN(Number(this.companyForm.tel)) || this.companyForm.tel < 10) {
        return '請填寫正確的聯絡電話';
      }
      if (!this.companyForm.email) {
        return '請填寫電子信箱';
      }
      if (!/^[A-Za-z0-9._\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.companyForm.email)) {
        return '請填寫正確的電子信箱';
      }
      if (!this.companyForm.agree) {
        return '請同意隱私權及個資保護聲明';
      }

      return '';
    },
    async submitCompany() {
      // 先檢查 form 資料
      const swalErrorMsg = this.checkCompanyFormValue();

      if (swalErrorMsg) {
        this.$customSWAL({ icon: 'error', title: swalErrorMsg });
        return;
      }

      // 再檢查附件資料
      if (!this.checkAttach()) { return; }

      this.companySubmitBtnDisable = true;

      Promise.all([
        this.sendAppendix(this.$refs.company_form_file.files[0]),
      ])
        .then(
          (result) => {
            if (result[0].assets[0]) {
              const appendixPath = result[0].assets[0].path;
              this.sendCompanyForm(appendixPath);
            } else {
              this.sendCompanyForm();
            }
            this.companySubmitBtnDisable = false;
          },
        );
    },

    changeRecruitAppendix() {
      if (this.$refs.recruit_form_file.files[0]) {
        if (
          this.$refs.recruit_form_file.files[0].type === 'application/pdf'
          && this.$refs.recruit_form_file.files[0].size < 10 * 1024 * 1024
        ) {
          this.recruitForm.appendixName = this.$refs.recruit_form_file.files[0].name;
        } else {
          this.$customSWAL({ icon: 'error', title: '限 PDF 格式，10MB 以內' });
          this.$refs.recruit_form_file.value = '';
          this.recruitForm.appendixName = '';
        }
      } else {
        this.recruitForm.appendixName = '';
      }
    },
    async sendRecruitForm(appendixPath = null) {
      console.log('sendRecruitForm', appendixPath);
      const result = await requestApi(
        'contact.recruit',
        {
          data: {
            cname: this.recruitForm.name,
            mobile: this.recruitForm.tel,
            email: this.recruitForm.email,
            memo: this.recruitForm.remark,
            agree: (this.recruitForm.agree) ? 'Y' : 'N',
            status: 'N',
            link: (appendixPath) ? `storage/uploads/${appendixPath}` : '',
          },
        },
      );

      if (result.error) {
        this.$customSWAL({ icon: 'error', title: result.error });
      } else {
        this.recruitForm.appendixName = '';
        this.$refs.recruit_form_file.value = '';
        this.$customSWAL({ icon: 'success', title: '已收到您的資訊' });
      }

      this.resetRecuritForm();
    },
    checkRecuritFormValue() {
      if (!this.recruitForm.name) {
        console.log('name');
        return '請填寫姓名';
      }

      if (!this.recruitForm.tel) {
        console.log('tel1');
        return '請填寫聯絡電話';
      }

      if (Number.isNaN(Number(this.recruitForm.tel)) || this.recruitForm.tel < 10) {
        console.log('tel2');
        return '請填寫正確的聯絡電話';
      }

      if (!this.recruitForm.email) {
        console.log('email');
        return '請填寫電子信箱';
      }

      if (!/^[A-Za-z0-9._\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.recruitForm.email)) {
        return '請填寫正確的電子信箱';
      }

      if (!this.recruitForm.agree) {
        console.log('agree');
        return '請同意隱私權及個資保護聲明';
      }

      return '';
    },
    async submitRecruit() {
      console.log('recurit', 111);
      // 先檢查 form 資料
      const swalErrorMsg = this.checkRecuritFormValue();

      console.log('recurit', 222);
      if (swalErrorMsg) {
        this.$customSWAL({ icon: 'error', title: swalErrorMsg });
        return;
      }

      // 再檢查附件資料
      if (!this.checkAttach()) { return; }

      console.log('recurit', 333);

      this.recruitSubmitBtnDisable = true;

      console.log('recurit', 444);

      Promise.all([
        this.sendAppendix(this.$refs.recruit_form_file.files[0]),
      ])
        .then(
          (result) => {
            console.log('recurit', 555);
            if (result[0].assets[0]) {
              const appendixPath = result[0].assets[0].path;
              this.sendRecruitForm(appendixPath);
            } else {
              this.sendRecruitForm();
            }
            this.recruitSubmitBtnDisable = false;
          },
        );
    },
  },
  created() {
    if (
      this.type !== 'company'
      && this.type !== 'recruit'
    ) {
      this.$router.push(
        {
          name: 'Contact',
          params: {
            type: 'company',
          },
        },
      );
    }
  },
  mounted() {
    // this.$customSWAL({ icon: 'error' });
  },
};
</script>

<style
  scoped
  src="@/assets/scss/contact.scss"
  lang="scss">
</style>
